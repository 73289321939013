.projects-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 64px 0 96px 0;
  color: var(--main-grey);
  z-index: 2;
  .projects-title {
    margin-left: 20px;
    padding: 24px 0;
    font-stretch: expanded;
    font-weight: 500;
    color: var(--main-pink);
  }
  .projects-box {
    width: 100%;
    display: flex;
    gap: 20px;
    .project-single {
      max-width: 40%;
      .img-wrap {
        position: relative;
        border-radius: 6px;
        .project-img-icons {
          display: none;
          gap: 20px;
          position: absolute;
          top: 50%;
          left: 50%;
          font-size: 2.5rem;
          color: var(--lighter-blue);
          transform: translate(-50%, -50%);
          z-index: 3;
          a {
            color: var(--dark-blue);
          }
          a:hover {
            color: var(--lighter-blue);
          }
        }
        .project-img {
          max-width: 100%;
          border-radius: 6px;
        }
      }
      .img-wrap::after {
        position: absolute;
        content: "";
        height: calc(100% - 20px);
        width: 0;
        top: 10px;
        left: 10px;
        background-color: var(--main-grey);
        opacity: 0.75;
        border-radius: 6px;
      }
      h4 {
        padding: 12px 0 8px 0;
        font-size: 20px;
      }
    }
    .project-single:hover {
      .img-wrap {
        .project-img-icons {
          display: flex;
          transition: all 0.7s;
        }
      }
      .img-wrap::after {
        width: calc(100% - 20px);
        transition: all 0.5s;
      }
    }
  }
}

@media (max-wodth: 990px) {
  .projects-container {
    .projects-box {
      .project-single {
        .img-wrap {
          .project-img-icons {
            font-size: 2rem;
          }
        }
      }
    }
  }
}

@media (max-width: 990px) {
  .projects-container {
    .projects-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      .project-single {
        max-width: 80%;
        .img-wrap {
          .project-img-icons {
            font-size: 2.5rem;
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .projects-container {
    & .projects-title {
      font-size: 20px;
    }
  }
}
