.navbar {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  min-width: 180px;
  width: 15%;
  background-color: var(--main-grey);
  border: 1px solid var(--main-grey);
  box-shadow: 2px 2px 12px;
  height: 100%;
  margin: 0;
  transition: all 0.4s ease-out;
  & .navbar-nav {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  & .navbar-nav > .link {
    color: var(--darkest-blue);
    text-decoration: none;
    text-transform: uppercase;
    font-size: 20px;
    position: relative;
  }
  & .navbar-logo {
    margin: 64px 0;
  }
  & .navbar-logo {
    cursor: pointer;
    box-shadow: 2px 2px 12px var(--main-grey);
  }
  & .navbar-logo > * {
    width: 128px;
    height: auto;
    border: 4px solid var(--dark-blue);
    border-radius: 50%;
    box-shadow: 2px 2px 12px var(--main-grey);
  }
}

.link::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  border-radius: 25%;
  background-color: var(--dark-blue);
  bottom: -2px;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.5s ease-in-out;
}

.link:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}

.nav-btn {
  display: none;
  position: absolute;
  top: 6px;
  left: 6px;
  align-items: center;
  justify-content: center;
  background-color: var(--main-orange);
  border: var(--dark-blue) solid 3px;
  border-radius: 50%;
  .menu-icon {
    transform: scale(1.5);
  }
}

.nav-icon {
  display: block;
  position: relative;
  width: 50px;
  height: 50px;
  .nav-icon__cheeckbox {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: relative;
    z-index: 2;
    -webkit-touch-callout: none;
    opacity: 0;
  }
  div {
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 22px;
    height: 12px;
  }
  span {
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    background-color: var(--darkest-blue);
    border-radius: 1px;
    transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);
    &:first-of-type {
      top: 0;
    }
    &:last-of-type {
      bottom: 0;
    }
  }
  &.active,
  .nav-icon__cheeckbox:checked + div {
    span {
      &:first-of-type {
        transform: rotate(45deg);
        top: 5px;
      }
      &:last-of-type {
        transform: rotate(-45deg);
        bottom: 5px;
      }
    }
  }

  &.active:hover span:first-of-type,
  &.active:hover span:last-of-type,
  &:hover .nav-icon__cheeckbox:checked + div span:first-of-type,
  &:hover .nav-icon__cheeckbox:checked + div span:last-of-type {
    width: 22px;
  }

  & :hover {
    // no need hover effect on mobile.
    @media (max-width: 1024px) {
      span:first-of-type {
        width: 26px;
      }

      span:last-of-type {
        width: 12px;
      }
    }
  }
}

.footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
  text-align: center;
  border-top: 1px solid var(--darkest-blue);
  padding: 6px 0;
}

.extra-link {
  position: absolute;
  bottom: 36px;
  text-align: left;
  font-size: 16px;
  font-style: italic;
  > * {
    text-decoration: none;
    color: var(--darkest-blue);
  }
}

@media (max-width: 770px) {
  .nav-btn {
    display: flex;
    transform: translateX(0);
    transition: all 0.39s ease-out;
    z-index: 4;
  }

  .navbar {
    transform: translateX(-100%);
    transition: all 0.4s ease-out;
  }

  #btn-right {
    position: fixed;
    top: 2px;
    left: 180px;
    transform: translateX(-50%);
    transition: all 0.39s ease-out;
  }

  #isVisible {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    position: relative;
    background-color: var(--main-grey);
    height: 100%;
    margin: 0;
    position: fixed;
    top: 0px;
    left: 0px;
    transform: translateX(0);
    transition: all 0.4s ease-out;
    z-index: 3;
  }
}
