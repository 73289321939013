.about {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  z-index: 2;
  min-height: 600px;
  margin: 64px 0 96px 0;

  & .about-info {
    & .about-title {
      margin-left: 20px;
      padding: 24px 0;
      font-stretch: expanded;
      font-weight: 500;
      color: var(--main-pink);
    }
    & .about-description {
      display: flex;
      flex-direction: column;
      gap: 12px;
      font-size: 18px;
      margin-bottom: 32px;
      color: var(--main-grey);
    }
  }

  .skills {
    color: var(--main-grey);
    & h3 {
      margin-bottom: 24px;
      font-weight: 300;
    }
  }
  .skills-box {
    width: 340px;
    min-height: 120px;
    position: relative;
    & > * {
      display: flex;
      align-items: center;
      justify-content: center;
      //border: 2px solid var(--darkest-blue);
      border-radius: 50%;
      position: absolute;
      transition: all 0.5s;
    }
  }
}

.github {
  display: flex;
  & > .gh-link {
    text-decoration: none;
    color: var(--main-orange);
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    & > * {
      margin-left: 8px;
    }
    & .fa-github {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      border: 2px solid var(--main-orange);
      border-radius: 50%;
    }
    margin-bottom: 24px;
  }
}
.bubble1 {
  width: 53px;
  height: 53px;
  background-color: var(--main-orange);
  top: 0px;
  left: 6px;
}

.bubble1:hover {
  width: 58px;
  height: 58px;
  top: -4px;
  left: 10px;
}

.bubble2 {
  width: 55px;
  height: 55px;
  background-color: var(--main-pink);
  color: var(--lighter-blue);
  top: 20px;
  left: 70px;
}

.bubble2:hover {
  width: 60px;
  height: 60px;
  top: 26px;
}

.bubble3 {
  width: 56px;
  height: 56px;
  background-color: var(--main-orange);
  top: 8px;
  left: 146px;
}

.bubble3:hover {
  width: 60px;
  height: 60px;
  top: 4px;
  left: 150px;
}

.bubble4 {
  width: 58px;
  height: 58px;
  background-color: var(--main-pink);
  color: var(--lighter-blue);
  top: 48px;
  left: 230px;
}

.bubble4:hover {
  width: 64px;
  height: 64px;
  top: 45px;
}

.bubble5 {
  width: 56px;
  height: 56px;
  background-color: var(--main-orange);
  top: 120px;
  left: 290px;
}

.bubble5:hover {
  width: 60px;
  height: 60px;
}

.bubble6 {
  width: 58px;
  height: 58px;
  background-color: var(--main-pink);
  color: var(--lighter-blue);
  top: 105px;
  left: 130px;
}

.bubble6:hover {
  width: 62px;
  height: 62px;
}

.bubble7 {
  width: 56px;
  height: 56px;
  background-color: var(--main-orange);
  top: 80px;
  left: 12px;
}

.bubble7:hover {
  width: 60px;
  height: 60px;
  top: 90px;
  left: 20px;
}

@media (max-width: 568px) {
  .about {
    & .about-info {
      & .about-title {
        font-size: 20px;
      }
    }
  }
  .bubble5 {
    left: 20px;
    top: 90px;
  }
  .github {
    & > .gh-link {
      font-size: 18px;
    }
  }
}

@media (min-width: 992px) {
  .about {
    & .about-info {
      & .about-description {
        font-size: 20px;
      }
    }
  }
}

@media (min-width: 1680px) {
  .about {
    & .about-info {
      & .about-description {
        font-size: 22px;
      }
    }
  }
}
