.extra-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 64px 0 96px 0;
  color: var(--main-grey);
  z-index: 2;
  .extra-title {
    margin-left: 20px;
    padding: 24px 0;
    font-stretch: expanded;
    font-weight: 500;
    color: var(--main-pink);
    font-style: italic;
  }
  .extra-about {
    font-weight: 500;
    font-size: 22px;
  }
  .extra-box {
    width: 100%;
    display: flex;
    gap: 20px;
    justify-content: center;
  }
}

.extra-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  .extra-label {
    font-family: inherit;
  }
  .extra-input {
    padding: 6px;
    border-radius: 6px;
    border: none;
  }
  .extra-btn {
    padding: 10px;
    background-color: var(--main-orange);
    color: var(--darkest-blue);
    border-radius: 8px;
    padding: 8px 12px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
  }
}
// CAROUSEL

.carousel {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .carousel-title {
    margin: 12px 0;
    font-size: 20px;
  }

  .carousel-inner {
    width: 60%;
    max-height: 450px;
    display: flex;
    gap: 8px;
    .left {
      flex: 8%;
      display: grid;
      place-items: center;
    }
    .carousel-img {
      flex: 82%;
      height: 100%;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 6px;
      }
    }
    .right {
      flex: 8%;
      display: grid;
      place-items: center;
      text-align: center;
    }
    .carousel-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 38px;
      height: 38px;
      cursor: pointer;
      font-size: 24px;
      background-color: rgb(0, 0, 0, 0.4);
      border-radius: 50%;
    }
  }
}

@media (max-width: 1200px) {
  .carousel {
    .carousel-inner {
      width: 75%;
    }
  }
}

@media (max-width: 996px) {
  .carousel {
    .carousel-inner {
      width: 80%;
      max-height: 350px;
    }
  }
}

@media (max-width: 770px) {
  .extra-container {
    .extra-about {
      font-weight: 500;
      font-size: 20px;
    }
  }
  .carousel {
    .carousel-title {
      margin: 24px 0;
      font-size: 20px;
    }
    .carousel-inner {
      width: 90%;
      max-height: 350px;
    }
  }
}

@media (max-width: 470px) {
  .carousel {
    .carousel-title {
      font-size: 18px;
    }
    .carousel-inner {
      width: 90%;
      max-height: 350px;
      .carousel-btn {
        width: 32px;
        height: 32px;
        font-size: 20px;
      }
    }
  }
}

@media (max-width: 390px) {
  .extra-container {
    .extra-about {
      font-weight: 400;
      font-size: 18px;
    }
  }
  .carousel {
    .carousel-title {
      margin: 24px 0;
      font-size: 16px;
    }
    .carousel-inner {
      width: 95%;
      max-height: 300px;
      .carousel-btn {
        width: 32px;
        height: 32px;
        font-size: 20px;
      }
    }
  }
}
