.home {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 96px;
  width: 100%;
  min-height: 600px;
  margin: 64px 0;
  & .home-img {
    position: relative;

    & img {
      width: 280px;
      height: auto;
      content: contain;
      border-radius: 10px;
    }
  }
  & .home-info {
    position: relative;
    & .home-name {
      color: var(--main-pink);
      font-weight: 500;
      text-transform: uppercase;
      font-size: 36px;
      margin-bottom: 12px;
    }
    & .home-description {
      font-size: 24px;
      color: var(--main-orange);
    }
  }
}

.img::before {
  content: "";
  height: 60px;
  width: 2px;
  border: 1px solid var(--main-grey);
  border-radius: 25px;
  background-color: var(--main-grey);
  opacity: 0.8;
  position: absolute;
  top: 0px;
  left: -20px;
}

.home-img::before {
  content: "";
  height: 2px;
  width: 80px;
  border: 1px solid var(--main-grey);
  border-radius: 25px;
  background-color: var(--main-grey);
  opacity: 0.8;
  position: absolute;
  top: -20px;
  left: 0px;
  transition: 0.5s ease-in-out;
}

.home-img::after {
  content: "";
  height: 2px;
  width: 80px;
  border: 1px solid var(--main-grey);
  border-radius: 25px;
  background-color: var(--main-grey);
  opacity: 0.8;
  position: absolute;
  bottom: -20px;
  right: 0px;
  transition: 0.5s ease-in-out;
}

.home-img:hover::before {
  width: 120px;
  opacity: 1;
  transition: 0.5s ease-in-out;
}

.home-img:hover::after {
  width: 120px;
  opacity: 1;
  transition: 0.5s ease-in-out;
}

.bubble {
  font-size: 14px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: var(--main-pink);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -64px;
  right: -24px;
  transition: all 0.5s;
  & a {
    text-decoration: none;
    color: var(--dark-blue);
  }
}

.bubble:hover {
  width: 45px;
  height: 45px;
}

@media (max-width: 992px) {
  .home {
    gap: 64px;
    & .home-img {
      margin-left: 16px;
    }
    & .home-info {
      & .home-name {
        font-size: 28px;
      }
      & .home-description {
        font-size: 20px;
      }
    }
  }
}

@media (max-width: 850px) {
  .home {
    gap: 24px;
    & .home-img {
      & img {
        width: 240px;
      }
    }
  }
}

@media (max-width: 768px) {
  .home {
    gap: 64px;
  }
}

@media (max-width: 650px) {
  .home {
    flex-direction: column;
    align-items: center;
    gap: 64px;
    & .home-img {
      margin-top: 48px;
    }
    & .home-info {
      & .home-name {
        font-size: 24px;
      }
      & .home-description {
        font-size: 18px;
      }
    }
  }
  .bubble {
    right: -8px;
  }
}

@media (max-width: 576px) {
  .home {
    gap: 48px;
    & .home-img {
      margin-top: 48px;
    }
    & .home-info {
      & .home-name {
        font-size: 24px;
      }
      & .home-description {
        font-size: 20px;
      }
    }
  }
  .bubble {
    right: -8px;
  }
}
