.contacts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //margin: 64px 0;
  min-height: 600px;
  width: 100%;
  color: var(--main-grey);
  & .all-contacts {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;
    & .contact-single {
      display: flex;
      gap: 10px;
      font-size: 20px;
    }
    & a {
      text-decoration: none;
      color: var(--main-grey);
    }
    & a:hover {
      color: var(--main-orange);
    }
    & .contact-item {
      font-family: "Rubik", sans-serif;
    }
  }
}

@media (max-width: 576px) {
  .contacts {
    margin: 128px 0;
    justify-content: flex-start;
    & .contact-single {
      padding-left: 60px;
      font-size: 19px;
    }
  }
}

@media (max-width: 486px) {
  .contacts {
    margin: 128px 0;
    .all-contacts {
      & .contact-single {
        padding-left: 20px;
        font-size: 18px;
      }
    }
  }
}

@media (min-width: 1400px) {
  .contacts {
    margin: 64px 0;
    .all-contacts {
      & .contact-single {
        padding-left: 20px;
        font-size: 24px;
      }
    }
  }
}
