.resume {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 64px;
  margin-bottom: 64px;
  width: 100%;
  z-index: 2;
  color: var(--dark-blue);
  & .resume-title {
    margin-left: 20px;
    padding: 24px 0;
    font-stretch: expanded;
    font-weight: 500;
    color: var(--main-pink);
  }
  & .exp-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
  }
  & .single-exp {
    list-style-type: none;
    background: rgb(241, 234, 237);
    background: linear-gradient(105deg, #ffe3e7, #fbe5ea, #f4e8ec, #f1eaed);
    border-radius: 6px;
    box-shadow: 2px 2px 10px var(--darkest-blue);
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    & > * {
      padding: 8px 0;
    }
    & .exp-official {
      width: 25%;
      padding-left: 12px;
    }
    & .exp-info {
      width: 70%;
      display: flex;
      flex-direction: column;
      gap: 8px;
      & .exp-title {
        font-weight: 500;
        font-size: 20px;
      }
      & .exp-title:hover {
        cursor: pointer;
      }
      & .title-btn {
        display: flex;
        justify-content: space-between;
        & button {
          width: 20px;
          height: 20px;
          border: 2px solid var(--darkest-blue);
          background-color: var(--main-orange);
          border-radius: 50%;
          margin-right: 8px;
        }
      }
      & .exp-workplace {
        font-size: 18px;
        color: var(--darkest-blue);
      }
      & .exp-details {
        color: var(--dark-blue);
        font-size: 16px;
        padding-right: 6px;
      }
    }
  }

  @media (max-width: 568px) {
    .single-exp {
      & .exp-official {
        padding-left: 8px;
        & .exp-date {
          font-size: 14px;
        }
      }
      & .exp-info {
        & .exp-title {
          font-size: 16px;
        }
        & .exp-workplace {
          font-size: 15px;
        }
        & .exp-details {
          font-size: 14px;
          padding-right: 4px;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .resume {
    & .resume-title {
      font-size: 20px;
    }
  }
}
