/* base */
@import "../src/css/base/base.css";
/* layout */
@import "../src/css//base/layout.css";
/* 3rd-party */
@import "../src/css/3rd-party/font-awesome.min.css";

/* components */

@import "../src/css/components/navbar.scss";
@import "../src/css/components/resume.scss";
@import "../src/css/components/home.scss";
@import "../src/css/components/about.scss";
@import "../src/css/components/contact.scss";
@import "../src/css/components/projects.scss";
@import "../src/css/components/extra.scss";

// @import "../components/button.css";
// @import "../components/footer.css";
// @import "../components/header.css";
/* CUSTOM STYLE BELOW THIS COMMENT LINE */

.App {
  text-align: center;
  height: 100%;
}

.main-page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin-left: 20%;
  position: relative;
}

.footer {
  font-size: 12px;
}

@media (max-width: 576px) {
  .main-page {
    margin-left: 40px;
  }
}

.wrap {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background-color: var(--dark-blue);
}

.bg {
  opacity: 0.28;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
